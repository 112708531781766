// import Vue from 'vue';

// import jQuery, { css } from 'jquery';
// global.jquery = jQuery;
// global.$ = jQuery;

// import jQuery from 'jquery';

// declare global {
//   interface Window {
//     $: typeof jQuery;
//   }
// }

// global.$ = jQuery;
import jQuery from 'jquery';
declare global {
  interface Window {
    $: typeof jQuery;
  }
}

window.$ = jQuery;

$(function(){

  // スムーススクロール
  var headerHeight = $('.header-bottom').outerHeight();
  var urlHash = location.hash;
  if(urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function(){
      var target = $(urlHash);
      var position = target.offset().top - 95;
      $('body,html').stop().animate({scrollTop:position}, 500);
    }, 100);
  }
  $('a[href^="#"]').click(function() {
    var href= $(this).attr("href");
    var target = $(href);
    var position = target.offset().top - 95;
    $('body,html').stop().animate({scrollTop:position}, 500);
  });
});



$(function() {
  setTimeout(function(){
  const speed = 200; //スピード 大きいとゆっくりになる
  let cnt = 0;
  const $text_left = jQuery('.text03,.text04');
  let letterNum = $text_left.find('span').length;
  let timerId = setInterval(function(){
    $text_left.find('span').eq(cnt).addClass('is-show');
    if(cnt === letterNum){
      clearInterval(timerId);
    }
    cnt++
  }, speed);
},3000);
});

$(function() {
  const speed = 200; //スピード 大きいとゆっくりになる
  let cnt = 0;
  const $text_right = jQuery('.text01,.text02');
  let letterNum = $text_right.find('span').length;
  let timerId = setInterval(function(){
    $text_right.find('span').eq(cnt).addClass('is-show');
    if(cnt === letterNum){
      clearInterval(timerId);
    }
    cnt++
  }, speed);
});


// $(function(){
//   $(window).scroll(function (){
//       $('.fadein').each(function(){
//           var position = $(this).offset().top;
//           var scroll = $(window).scrollTop();
//           var windowHeight = $(window).height();
//           if (scroll > position - windowHeight + 200){
//             $(this).addClass('active');
//           }
//       });
//       $('.fadein2').each(function(){
//         var position = $(this).offset().top;
//         var scroll = $(window).scrollTop();
//         var windowHeight = $(window).height();
//         if (scroll > position - windowHeight + 400){
//           $(this).addClass('active');
//         }
//     });
//     $('.fadein3').each(function(){
//       var position = $(this).offset().top;
//       var scroll = $(window).scrollTop();
//       var windowHeight = $(window).height();
//       if (scroll > position - windowHeight + 300){
//         $(this).addClass('active');
//       }
//   });
//   });
// });

// $(function() {
//   $('.front__header--menu--link.menu01').mouseover(function(e) {
// 	$('.front__header--character--nigariemon').addClass("lefttop");
//     })
//   $('.front__header--menu--link.menu01').mouseout(function(e) {
// 	$('.front__header--character--nigariemon').removeClass("lefttop");
//     })

//     $('.front__header--menu--link.menu02').mouseover(function(e) {
//       $('.front__header--character--nigariemon').addClass("righttop");
//         })
//       $('.front__header--menu--link.menu02').mouseout(function(e) {
//       $('.front__header--character--nigariemon').removeClass("righttop");
//         })

//         $('.front__header--menu--link.menu03').mouseover(function(e) {
//           $('.front__header--character--nigariemon').addClass("leftbottom");
//             })
//           $('.front__header--menu--link.menu03').mouseout(function(e) {
//           $('.front__header--character--nigariemon').removeClass("leftbottom");
//             })


//             $('.front__header--menu--link.menu04').mouseover(function(e) {
//               $('.front__header--character--nigariemon').addClass("rightbottom");
//                 })
//               $('.front__header--menu--link.menu04').mouseout(function(e) {
//               $('.front__header--character--nigariemon').removeClass("rightbottom");
//                 })

// });


// $(document).ready(function(){
//   $('.cafe-archive__contents--list').hover(
//     function(){
//       $(this).find('.cafe-archive__contents--list--link--btn').addClass('shake');
//     },
//     function(){
//       $(this).find('.cafe-archive__contents--list--link--btn').removeClass('shake');
//     }
//   );
// });

// const parentLink = document.querySelector('.header__nav--list--link.parent');
// const childList = document.querySelector('.header__nav--child');

// parentLink.addEventListener('click', function(e) {
//   e.preventDefault();
//   this.classList.toggle('active');
//   childList.classList.toggle('active');
//   if (childList.style.maxHeight) {
//     childList.style.maxHeight = null;
//   } else {
//     childList.style.maxHeight = childList.scrollHeight + 'px';
//   }
// });

//カフェホバー
$(document).ready(function() {
  $('.cafe-archive__contents--list').hover(
      function() {
          $(this).find('.cafe-archive__contents--list--comment--title').addClass('hover');
          $(this).find('.cafe-archive__contents--list--link--btn').addClass('shake');
          $(this).find('.cafe-archive__contents--list--detail--btn').addClass('hover');
      },
      function() {
          $(this).find('.cafe-archive__contents--list--comment--title').removeClass('hover');
          $(this).find('.cafe-archive__contents--list--detail--btn').removeClass('hover');
          $(this).find('.cafe-archive__contents--list--link--btn').removeClass('shake');
      }
  );
});

//福利厚生ホバー
$(document).ready(function() {
  $('.welfare-archive__contents--list').hover(
      function() {
          $(this).find('.welfare-archive__contents--list--comment--title').addClass('hover');
          $(this).find('.welfare-archive__contents--list--detail').addClass('hover');
          $(this).find('.welfare-archive__contents--list--link--btn').addClass('shake');
          $(this).find('.welfare-archive__contents--list--detail--btn').addClass('hover');
      },
      function() {
          $(this).find('.welfare-archive__contents--list--comment--title').removeClass('hover');
          $(this).find('.welfare-archive__contents--list--detail').removeClass('hover');
          $(this).find('.welfare-archive__contents--list--link--btn').removeClass('shake');
          $(this).find('.welfare-archive__contents--list--detail--btn').removeClass('hover');
      }
  );
});

//化成事業ホバー
$(document).ready(function() {
  $('.chemical-archive__contents--list').hover(
      function() {
          $(this).find('.chemical-archive__contents--list--comment--title').addClass('hover');
          $(this).find('.chemical-archive__contents--list--link--btn').addClass('shake');
          $(this).find('.chemical-archive__contents--list--detail--btn').addClass('hover');
      },
      function() {
          $(this).find('.chemical-archive__contents--list--comment--title').removeClass('hover');
          $(this).find('.chemical-archive__contents--list--detail--btn').removeClass('hover');
          $(this).find('.chemical-archive__contents--list--link--btn').removeClass('shake');
      }
  );
});

// $(document).ready(function(){
//   　if ($(window).width() < 768) {
// const navToggle = document.querySelector('.header__nav--toggle');
// const nav = document.querySelector('.header__nav');

// navToggle.addEventListener('click', function() {
//   nav.classList.toggle('active');
//   if (nav.classList.contains('active')) {
//     nav.style.height = nav.scrollHeight + "px";
//   } else {
//     nav.style.height = "0";
//   }
// });

document.addEventListener('DOMContentLoaded', function() {
  if (window.innerWidth < 768) {
    const navToggle = document.querySelector('.header__nav--toggle');
    const nav = document.querySelector('.header__nav') as HTMLElement;

    navToggle.addEventListener('click', function() {
      nav.classList.toggle('active');
      if (nav.classList.contains('active')) {
        nav.style.height = nav.scrollHeight + "px";
      } else {
        nav.style.height = "0";
      }
    });
  }
});


document.addEventListener('DOMContentLoaded', function() {
  const parentLinks = document.querySelectorAll('.header__nav--list--link.parent');
  for (let i = 0; i < parentLinks.length; i++) {
    const parentLink = parentLinks[i];
    parentLink.addEventListener('click', function(event) {
      event.preventDefault();
      const childList = parentLink.nextElementSibling;
      childList.classList.toggle('active');
      parentLink.classList.toggle('active');
    });
  }
});

//企業理念パララックス
// $(window).on("load scroll", function() {
//   var scrollTop = $(this).scrollTop();
//   var windowHeight = $(this).height();
//   console.log(windowHeight);
//   $(".philosophy__contents--article").each(function() {
//     var position = $(this).offset().top;
//     var delay = 100;
//     if (position < scrollTop + windowHeight - delay) {
//       $(this).addClass("fadein");
//     } else {
//       $(this).removeClass("fadein");
//     }
//   });
// });

// 企業理念 要素を取得
// JavaScript
const items = document.querySelectorAll('.target');
const offset = 50; // 要素が画面上部から50px以内に来たら.activeクラスを追加する

function addActiveClass() {
  items.forEach(item => {
    const rect = item.getBoundingClientRect();
    if (rect.top <= window.innerHeight - offset && rect.bottom >= offset) {
      item.classList.add('active');
    } else {
      item.classList.remove('active');
    }
  });
}

window.addEventListener('scroll', addActiveClass);
